<template>
	<div
		:id="blockId"
		class="block-product-wrapper"
		:[DATA_ATTRIBUTE_ANIMATION_ROLE]="DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT"
	>
		<ProductSkeletonLoader
			v-if="isLoading || !productData"
			:image-border-radius="imageBorderRadius"
			:text-align="textAlign"
		/>
		<div
			v-else
			class="block-product"
			:class="{
				'block-product--centered': textAlign === 'center',
				'block-product--in-preview': isQuickPreview
			}"
			:style="computedStyles"
		>
			<Carousel
				:images="productData.images"
				:product-title="productData.title"
				:arrows-color="navigationArrowsColor"
				:navigation-thumbnail-arrows-color="navigationThumbnailArrowsColor"
				:gallery-placement="galleryPlacement"
				:image-ratio="imageRatio"
				:image-border-radius="imageBorderRadius"
				:is-eager="isEager"
				:site-id="siteId"
				:variant-image="variantImage"
				:is-quick-preview="isQuickPreview"
				:is-variant-image-preselected="isProductPriceRangeShown(productData)"
				@image-click="$emit('image-click', $event)"
			/>
			<div class="block-product__content-wrapper">
				<div class="block-product__price-data-wrapper">
					<h1
						v-if="isBlockInProductPage"
						v-qa="'builder-product-section-title'"
						class="block-product__title"
					>
						{{ productData.title }}
					</h1>
					<h3
						v-else
						v-qa="'builder-product-section-title'"
						class="block-product__title"
					>
						{{ productData.title }}
					</h3>
					<h5 class="block-product__subtitle">
						{{ productData.subtitle }}
					</h5>
					<div
						class="block-product__price-wrapper"
						:class="{ 'block-product__price-wrapper--with-duration': isProductTypeBooking }"
					>
						<p
							v-if="isPriceShown"
							class="block-product__price body-large"
							:class="{ 'block-product__price--sale': priceData.sale_amount }"
						>
							{{ formatPrice(priceData.amount, priceData.currency) }}
						</p>
						<div class="block-product__additional-info">
							<p
								v-if="priceData.sale_amount && isPriceShown"
								class="block-product__price body-large"
							>
								{{ formatPrice(priceData.sale_amount, priceData.currency) }}
							</p>
							<p
								v-if="isProductTypeBooking"
								class="block-product__duration body-large"
							>
								{{ formattedBookingDuration }}
							</p>
						</div>
					</div>
					<p
						v-if="isProductTypeBooking"
						class="block-product__location"
					>
						{{ location }}
					</p>
					<div
						v-if="productData.options.length"
						class="block-product__option-select-wrapper"
					>
						<OptionSelect
							v-for="(option, index) in productData.options"
							:key="`option-${index}`"
							:value="selectedOption(index)"
							:options="uniqueOptionSelections[index]"
							:title="option.title"
							class="block-product__option-select"
							:class="{ 'block-product__option-select--centered' : textAlign === 'center' }"
							label-key="value"
							@set-value="handleVariantOptionChange($event)"
						/>
					</div>
					<div
						v-if="!isProductTypeBooking && isQuantityPickerEnabled"
						class="block-product__quantity-wrapper"
						:class="{ 'block-product__quantity-wrapper--disabled' : isOutOfStock || isAddToCartDisabled }"
					>
						<QuantityPicker
							qa-selector="productpage"
							:quantity="selectedQuantity"
							:is-limit-reached="isLimitReached"
							:is-stock-available="isStockAvailable"
							@quantity-change="handleQuantityChange"
						/>
						<p
							v-if="isStockInfoShown"
							class="block-product__stock-text"
						>
							{{ stockInfoText }}
						</p>
					</div>
				</div>
				<div class="block-product__button-wrapper">
					<GridButton
						v-qa="`productsection-btn-addtobag`"
						:type="blockButtonType"
						:content="buttonText"
						class="block-product__button"
						:class="`block-product__button--${blockButtonType}`"
						:is-loading="isCheckoutLoading"
						tag-name="button"
						:disabled="isAddToCartDisabled"
						:border-width="blockButtonBorderWidth"
						:border-color="buttonBorderColor.normal"
						:border-color-hover="buttonBorderColor.hover"
						:background-color="buttonBackgroundColor.normal"
						:background-color-hover="buttonBackgroundColor.hover"
						@click="handleButtonClick"
					/>
				</div>
				<Transition>
					<p
						v-if="isBookingProductInCart"
						class="block-product__notice"
					>
						{{ translations.purchaseToBookAgain }}
					</p>
				</Transition>
				<p
					v-if="productData.description && !isQuickPreview"
					class="block-product__description"
					v-html="productData.description"
				/>
				<RouterLink
					v-else-if="isQuickPreview"
					:to="{ path: productPagePath }"
					class="block-product__link body-large"
					@click="$emit('click-more')"
				>
					{{ translations.moreDetails }}
				</RouterLink>
			</div>
		</div>
	</div>
</template>

<script setup>
import Carousel from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/Carousel.vue';
import OptionSelect from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/OptionSelect.vue';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import formatPrice from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import {
	PRODUCT_TYPE_BOOKING,
	SITE_PRODUCT_SELECTION,
	SITE_PRODUCT_SELECTION_TYPE_LOWEST,
	MAX_PRODUCTS_IN_CART,
	DEFAULT_EMPTY_PRODUCT_VALUE,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getVariantQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import ProductSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSkeletonLoader.vue';
import QuantityPicker from '@zyro-inc/site-modules/components/ecommerce/-partials/QuantityPicker.vue';
import {
	computed,
	watch,
	ref,
	onMounted,
} from 'vue';
import {
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
} from '@zyro-inc/site-modules/constants';

const MAX_STOCK_AMOUNT_TO_SHOW = 5;
const DEFAULT_PICKER_VALUE = 1;

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	productData: {
		type: Object,
		default: null,
	},
	blockStyle: {
		type: Object,
		default: () => ({}),
	},
	textColorVars: {
		type: Object,
		default: () => ({}),
	},
	blockButtonText: {
		type: String,
		default: null,
	},
	blockButtonStyle: {
		type: Object,
		default: () => ({}),
	},
	blockButtonType: {
		type: String,
		default: 'primary',
	},
	blockButtonBorderWidth: {
		type: Number,
		default: 0,
	},
	navigationArrowsColor: {
		type: String,
		default: null,
	},
	navigationThumbnailArrowsColor: {
		type: String,
		default: null,
	},
	galleryPlacement: {
		type: String,
		default: null,
	},
	imageRatio: {
		type: String,
		default: 'cover',
	},
	imageBorderRadius: {
		type: String,
		default: '0%',
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	isCheckoutLoading: {
		type: Boolean,
		default: false,
	},
	canAddToCart: {
		type: Function,
		default: () => true,
	},
	isEager: {
		type: Boolean,
		default: false,
	},
	translations: {
		type: Object,
		default: null,
	},
	quantifiedCartItemsList: {
		type: Array,
		default: () => ([]),
	},
	isQuantityPickerEnabled: {
		type: Boolean,
		default: false,
	},
	productPages: {
		type: Object,
		default: () => ({}),
	},
	isQuickPreview: {
		type: Boolean,
		default: false,
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
	siteId: {
		type: String,
		default: null,
	},
	shoppingCartItems: {
		type: Array,
		default: () => [],
	},
	variantsQuantity: {
		type: Array,
		default: () => [],
	},
	currentPageType: {
		type: String,
		default: 'default',
	},
});
const emit = defineEmits([
	'buy-button-click',
	'click-more',
	'image-click',
]);
const selectedQuantity = ref(1);
const variantImage = ref(null);
const isLimitReached = ref(false);
const selectedVariant = ref(DEFAULT_EMPTY_PRODUCT_VALUE);

const isBlockInProductPage = computed(() => props.currentPageType === PAGE_TYPE_ECOMMERCE_PRODUCT);
const buttonBackgroundColor = computed(() => ({
	normal: props.blockButtonStyle[`grid-button-${props.blockButtonType}-background-color`],
	hover: props.blockButtonStyle[`grid-button-${props.blockButtonType}-background-color-hover`],
}));
const buttonBorderColor = computed(() => ({
	hover: props.blockButtonStyle[`grid-button-${props.blockButtonType}-border-color-hover`],
	normal: props.blockButtonStyle[`grid-button-${props.blockButtonType}-border-color`],
}));
const textAlign = computed(() => props.blockStyle?.textAlign);
const productData = computed(() => props.productData);
const uniqueOptionSelections = computed(() => {
	if (!productData.value.options.length) {
		return [];
	}

	return productData.value.options.map((option) => ({
		...option.values
			.filter((selection, index, self) => self.findIndex((selectionTwo) => selectionTwo.value === selection.value) === index),
	}));
});
const selectedValueVariant = computed(() => selectedVariant.value?.variants[0]);

const selectedOption = (index) => {
	const optionValue = selectedValueVariant.value?.options.find(
		(option) => option.option_id === uniqueOptionSelections.value[index][0].option_id,
	);

	return Object.values(uniqueOptionSelections.value[index]).find((option) => optionValue?.value === option.value)?.id;
};

const priceData = computed(() => (selectedVariant.value.id
	? selectedValueVariant.value?.prices[0]
	: productData.value?.variants[0].prices[0]));

const quantityInCart = computed(() => props.quantifiedCartItemsList
	.find((item) => item.product.variants[0].id === selectedValueVariant.value?.id)?.quantity || 0);
const totalQuantitySelected = computed(() => {
	if (!props.isCartVisible) {
		return selectedQuantity.value;
	}

	return selectedQuantity.value + quantityInCart.value;
});
const isStockAvailable = computed(() => {
	if (selectedValueVariant.value?.manage_inventory) {
		return totalQuantitySelected.value < getVariantQuantity({
			variantsQuantity: props.variantsQuantity,
			variantId: selectedValueVariant.value?.id,
		});
	}

	return true;
});
const computedStyles = computed(() => ({
	...objectToCssVariables({
		...props.textColorVars,
		...props.blockButtonStyle,
	}),
}));

const isPriceShown = computed(() => !productData.value?.options.length || (productData.value?.options.length && selectedVariant.value));
const isProductTypeBooking = computed(() => productData.value?.type.value === PRODUCT_TYPE_BOOKING);
const isBookingProductInCart = computed(() => isProductTypeBooking.value
      && props.shoppingCartItems?.some((item) => item.id === productData.value?.id));
const isAddToCartDisabled = computed(() => isBookingProductInCart.value
|| !props.canAddToCart(productData.value?.id, selectedVariant.value?.variants[0].id));
const buttonText = computed(() => {
	if (isBookingProductInCart.value) {
		return `\u2713 ${props.translations.booked}`;
	}

	return props.blockButtonText || props.translations?.addToBag || 'Add to bag';
});
const location = computed(() => productData.value?.variants[0].booking_event?.location);
const formattedBookingDuration = computed(() => getFormattedBookingDuration(productData.value, props.translations));
const isOutOfStock = computed(() => selectedValueVariant.value?.manage_inventory && getVariantQuantity({
	variantsQuantity: props.variantsQuantity,
	variantId: selectedValueVariant.value?.id,
}) === 0);
const isStockInfoShown = computed(() => !!selectedValueVariant.value?.manage_inventory);
const productPagePath = computed(() => {
	if (!props.productPages) {
		return '/';
	}

	const productPage = Object.values(props.productPages).find((page) => page.productId === productData.value.id);

	if (!productPage) {
		return '/';
	}

	return `/${productPage.slug}`;
});
const stockInfoText = computed(() => {
	const quantity = getVariantQuantity({
		variantsQuantity: props.variantsQuantity,
		variantId: selectedValueVariant.value?.id,
	});

	if (isOutOfStock.value || quantityInCart.value === quantity) {
		return props.translations.outOfStock;
	}

	const prefix = quantity <= MAX_STOCK_AMOUNT_TO_SHOW ? quantity : `${MAX_STOCK_AMOUNT_TO_SHOW}+`;

	return `${prefix} ${props.translations.inStock} `;
});

const getProductWithSelectedVariant = (variant) => ({
	...productData.value,
	variants: [variant],
});

const getInitiallySelectedVariant = () => {
	if (!productData.value) {
		return DEFAULT_EMPTY_PRODUCT_VALUE;
	}

	if (productData.value[SITE_PRODUCT_SELECTION] === SITE_PRODUCT_SELECTION_TYPE_LOWEST) {
		const firstLowestPriceVariant = productData.value.variants.reduce((acc, curr) => {
			const accPrice = acc.prices[0].sale_amount || acc.prices[0].amount;
			const currPrice = curr.prices[0].sale_amount || curr.prices[0].amount;

			return accPrice <= currPrice ? acc : curr;
		});

		const uniqueOptionValues = uniqueOptionSelections.value.map(
			(item) => Object.values(item).find(
				(opt) => firstLowestPriceVariant?.options.some((opt2) => opt2.value === opt.value),
			),
		);

		const preselectedVariant = {
			...firstLowestPriceVariant,
			options: [...uniqueOptionValues],
		};

		return getProductWithSelectedVariant(preselectedVariant);
	}

	// this preselects the matching variant by the default options (uniqueOptionSelections) inside option selects
	const preselectedVariant = productData.value.variants
		.find((variant) => variant.options
			.every((option) => uniqueOptionSelections.value
				.some((selection) => selection[0].value === option.value)));

	return getProductWithSelectedVariant(preselectedVariant || productData.value.variants[0]);
};

const handleButtonClick = () => {
	const productForShoppingCart = new Array(selectedQuantity.value).fill(selectedVariant.value);

	selectedQuantity.value = DEFAULT_PICKER_VALUE;

	emit('buy-button-click', productForShoppingCart);
};

const handleVariantOptionChange = (id) => {
	selectedQuantity.value = DEFAULT_PICKER_VALUE;

	const optionSelection = productData.value.options.map(
		(option) => option.values.find((optionValue) => optionValue.id === id),
	).find((item) => item);

	const remainingVariantOptions = selectedValueVariant.value?.options
		.filter((option) => option.option_id !== optionSelection.option_id);

	const allVariantOptions = [
		...remainingVariantOptions,
		optionSelection,
	];

	// find correct variant with the same option values
	const matchedVariant = productData.value.variants.find((variant) => variant.options
		.every((option) => allVariantOptions
			.some((value) => value.value === option.value && value.option_id === option.option_id)));

	if (matchedVariant) {
		selectedVariant.value = getProductWithSelectedVariant(matchedVariant);
	}
};

const handleQuantityChange = (value) => {
	const isQuantityTracked = selectedValueVariant.value?.manage_inventory;

	isLimitReached.value = (props.shoppingCartItems.length + value) >= MAX_PRODUCTS_IN_CART;

	if (isQuantityTracked) {
		const maxQuantity = getVariantQuantity({
			variantsQuantity: props.variantsQuantity,
			variantId: selectedValueVariant.value?.id,
		});

		if ((quantityInCart.value + value) > (maxQuantity || isLimitReached.value)) {
			// limit is the smaller value - either available stock amount or available cart amount
			const limit = Math.min(maxQuantity - quantityInCart.value, MAX_PRODUCTS_IN_CART - props.shoppingCartItems.length);

			selectedQuantity.value = limit;

			return;
		}
	} else if (isLimitReached.value) {
		selectedQuantity.value = MAX_PRODUCTS_IN_CART - props.shoppingCartItems.length;

		return;
	}

	if (value <= 0) {
		selectedQuantity.value = DEFAULT_PICKER_VALUE;

		return;
	}

	selectedQuantity.value = value;
};

onMounted(() => {
	if (productData.value) {
		selectedVariant.value = getInitiallySelectedVariant();
	}
});

watch(productData, (newValue, oldValue) => {
	if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
		selectedVariant.value = getInitiallySelectedVariant();
	}
});
watch(selectedVariant, (newValue, oldValue) => {
	if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
		if (newValue?.variants[0].image_url) {
			variantImage.value = newValue.variants[0].image_url;
		}
	}
}, {
	immediate: true,
});
watch(() => props.shoppingCartItems, (newValue) => {
	const itemCount = newValue?.length || 0;

	// Since there is no 'this' in Vue 3 setup, we access the ref properties directly.
	selectedQuantity.value = DEFAULT_PICKER_VALUE;
	isLimitReached.value = (itemCount + selectedQuantity.value) >= MAX_PRODUCTS_IN_CART;
}, {
	immediate: true,
});

</script>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@import "@zyro-inc/site-modules/components/blocks/ecommerce/-partials/shared";
@include font-style("h3", ".block-product__title", ".block-product");
@include font-style("h5", ".block-product__subtitle", ".block-product");
@include font-style("body", "p,.block-product__option-select-wrapper", ".block-product");
@include font-style("body-large", ".body-large", ".block-product");

.block-product-wrapper {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);
}

.block-product {
	$this: &;

	display: flex;
	justify-content: center;
	width: 100%;
	max-width: var(--content-width);
	margin: 0 auto;
	text-align: left;

	&--centered {
		text-align: center;

		#{$this}__content-wrapper,
		#{$this}__price-data-wrapper,
		#{$this}__button-wrapper {
			align-items: center;
			text-align: center;
		}
	}

	&--in-preview {
		min-height: 500px;
		max-height: 700px;

		#{$this}__content-wrapper {
			justify-content: flex-start;
			padding-left: 32px;
		}

		#{$this}__price-data-wrapper {
			justify-content: flex-start;
			overflow: auto;
		}
	}

	&__stock-text {
		display: flex;
		align-items: center;
		margin-left: 24px;
	}

	&__content-wrapper,
	&__price-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: center;
		width: 100%;
	}

	&__content-wrapper {
		max-width: 624px;
		padding-left: 80px;
		word-break: break-word;
	}

	&__button-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		margin-top: 32px;
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);

		&--primary {
			margin: calc(-1 * var(--grid-button-primary-border-null, var(--grid-button-primary-border-width, 0)));

			&:focus,
			&:hover {
				margin: calc(-1 * var(--grid-button-primary-border-null-hover, var(--grid-button-primary-border-width-hover, 0)));
			}
		}

		&--secondary {
			margin: calc(-1 * var(--grid-button-secondary-border-null, var(--grid-button-secondary-border-width, 0)));

			&:focus,
			&:hover {
				margin:
					calc(
						-1 * var(--grid-button-secondary-border-null-hover, var(--grid-button-secondary-border-width-hover, 0))
					);
			}
		}
	}

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 16px;
	}

	&__price-wrapper {
		display: flex;
		flex-wrap: wrap;

		&--with-duration {
			margin-bottom: 8px;
		}
	}

	&__additional-info {
		display: flex;
	}

	&__price {
		margin-bottom: 0;

		&--sale {
			margin-right: 8px;
			opacity: 0.4;

			&#{&} {
				text-decoration: line-through;
			}
		}
	}

	&__duration {
		&::before {
			margin: 0 8px;
			content: "|";
		}
	}

	&__location,
	&__notice {
		opacity: 0.6;
	}

	&__option-select-wrapper {
		width: 100%;
		margin-top: 32px;
	}

	&__option-select {
		&:not(:last-child) {
			margin-bottom: 24px;
		}

		&--centered {
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__quantity-wrapper {
		display: flex;
		margin-top: 32px;

		&--disabled {
			pointer-events: none;
			filter: opacity(0.4);
		}
	}

	&__notice {
		margin-top: 8px;
		margin-bottom: 32px;
		font-size: 14px;
	}

	&__link {
		margin-top: 32px;

		&#{&} {
			text-decoration: underline;
		}
	}

	&__description {
		width: 100%;
		margin-top: 56px;
		word-break: break-word;
		white-space: pre-line;

		blockquote {
			position: relative;
			display: flex;
			font-style: italic;

			&::before {
				margin-right: 0.3em;
				font-size: 4em;
				line-height: 1em;
				content: open-quote;
			}

			&::after {
				visibility: hidden;
				content: close-quote;
			}
		}

		p:empty::after {
			content: "\00A0";
		}

		ul,
		ol {
			padding-left: 1em;
			list-style-position: inside;

			p {
				display: inline;
			}
		}
	}
}

@include site-engine-mobile {
	.block-product-wrapper {
		padding: var(--m-block-padding);
	}

	.block-product {
		flex-direction: column;
		width: 100%;

		&--centered {
			align-items: center;
		}

		&__content-wrapper {
			max-width: unset;
			padding: 32px 0 0;
		}
	}
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
